<template>
  <div class="container">
    <!-- <van-nav-bar left-arrow title="编辑资料" right-text="保存"  @click-right="saveUser" @click-left="$router.back()"></van-nav-bar> -->
    <van-nav-bar title="设置" left-arrow @click-left="$router.back()" />
    <van-cell-group class="user-group">
      <van-cell icon="comment-circle-o" title="消息通知设置" to="/user/mysubscribe" is-link />
      <van-cell icon="service-o" title="意见反馈" to="/user/feedback" is-link />
      <!-- <van-cell icon="bullhorn-o" title="消息通知设置" to="/user/mysubscribe" is-link /> -->
      <van-cell
        icon="upgrade"
        title="系统更新记录"
        @click="clickupdate"
        to="/article/detail?para=18c909ad-6c71-4375-a4bb-9ac0873db787&type=104"
        is-link
      >
        <template #title>
          <span class="custom-title">系统更新记录</span>
          <i :class="gethasSystmeUpdate() ? 'icon_num_red' : ''"></i>
        </template>
      </van-cell>
      <van-cell @click="lgout" icon="warning-o" title="退出登录" is-link />
    </van-cell-group>
  </div>
</template>
  
  <script>
import {  mapGetters,mapMutations } from "vuex";
import { ReadUpdate } from "@/api/systemmessage";
export default {
  name: "user-SetingList",
  data() {
    return {};
  },
  created() {},
  methods: {
    ...mapMutations(["clearUser","updateHasSystemUpdate" ]),
    ...mapGetters([
      "gethasSystmeUpdate",
    ]),

    async clickupdate() {
      //更新服务器
      await ReadUpdate();
      //更新本地
      this.updateHasSystemUpdate(false);
    },

    // 退出登录
    async lgout() {
      try {
        await this.$dialog.confirm({
          title: "提示",
          message: "确定要退出登录么",
        });
        this.clearUser();
        //this.$router.push('/login')
        window.location.replace(window.location.origin + "/#/login");
        //window.location.reload(true);
        // window.location.href=window.location.origin+"/#/login";
        // window.location.reload(true);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.btn-box {
  padding: 20px;
}
.icon_num_red {
  margin-left: 8px;
  margin-right: 5px;
  font-weight: normal;
  background: #df3e3e;
  display: inline-block;
  width: 10px;
  height: 10px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  line-height: 16px;
  *line-height: 15px;
  border-radius: 50%;
  font-family: Arial;
}
</style>
  